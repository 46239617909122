table.individualTable {
  @apply font-sans;
}
table thead.individualTableThead {
  @apply border-b border-gray-400 m-0 top-0 sticky bg-white;
}

table td.individualTableTd {
  @apply border border-gray-400;
}

.individualTableTr:nth-of-type(even) {
  @apply bg-gray-100;
}

.selectedTr {
  @apply bg-secondary text-white !important;
}
