.small-datetime-picker {
    height: 24px;
}

.small-datetime-picker .react-datetime-picker__wrapper {
    border: none;
}

.small-datetime-picker .react-datetime-picker__button svg {
    height: 12px;
}