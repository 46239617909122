table {
  @apply font-sans w-full;
}

table td {
  @apply p-2;
}
table th {
  @apply p-2;
}

table th {
  @apply pt-3 pb-2 text-left text-black;
}
