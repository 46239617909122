.auth-image {
  @apply w-60 py-9;
}

.nav-logo {
  @apply object-contain h-full;
}

.footer-text {
  @apply text-white self-center p-2;
}

.auth-sub-heading {
  @apply text-text-grey text-sm self-center w-4/5;
}

.auth-heading {
  @apply text-2xl text-text-grey self-center w-4/5;
}

.auth-form-body {
  @apply flex flex-auto flex-col gap-5 py-2 items-center;
}
.auth-form-edit-body {
  @apply flex flex-auto flex-col py-2;
}

.auth-form-submit-button {
  @apply w-4/5 bg-primary hover:bg-primary-dark text-white text-base self-center;
}

.contain-content {
  contain: size;
}
